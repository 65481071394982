import { Logout, Settings } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, Divider, LinearProgress } from '@mui/material';
import ClassTwoToneIcon from '@mui/icons-material/ClassTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PermContactCalendarTwoToneIcon from '@mui/icons-material/PermContactCalendarTwoTone';
import ReplyIcon from '@mui/icons-material/Reply';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HistoryIcon from '@mui/icons-material/History';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import GavelIcon from '@mui/icons-material/Gavel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TollIcon from '@mui/icons-material/Toll';
import InventoryIcon from '@mui/icons-material/Inventory';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { UserRole } from '../../backend';
import { useTranslation } from 'react-i18next';

type NavigationEntry = [React.JSX.Element | null, string, string, UserRole[]];
type NavigationEntryWithSubEntries = [...NavigationEntry, NavigationEntry[]];

type Props = {};

const Navigation: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { role } = useAuthentication();
  const pathName = useLocation().pathname;

  const navigationEntries: (NavigationEntry | NavigationEntryWithSubEntries | null)[] = [
    [<EmailTwoToneIcon />, t('Orders'), '/orders/all-orders', []],
    [<ReplyIcon />, t('Returns'), '/shipments/inbound', []],
    [
      <SummarizeIcon />,
      t('Reports'),
      '/reports',
      [UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT],
      [
        [
          <InventoryIcon />,
          t('Shipments'),
          '/reports/shipments',
          [UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT],
        ],
        [
          <TollIcon />,
          t('Duties & VAT'),
          '/reports/duties-and-vat',
          [UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT],
        ],
      ],
    ],
    [
      <PermContactCalendarTwoToneIcon />,
      t('Customers'),
      '/crm/customers',
      [UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT],
    ],
    [
      <ClassTwoToneIcon />,
      t('Articles'),
      '/articles',
      [UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT],
    ],
    [
      <AccountTreeTwoToneIcon />,
      t('Classification'),
      '/article-categories/edit',
      [UserRole.ADMIN, UserRole.CUSTOMS_AGENT],
    ],
    [
      <GavelIcon />,
      t('Customs'),
      '/customs',
      [UserRole.ADMIN, UserRole.CUSTOMS_AGENT, UserRole.WAREHOUSE_AGENT, UserRole.WAREHOUSE_SUPERVISOR],
      [
        [<LocalShippingIcon />, t('Tours'), '/customs/tours', [UserRole.ADMIN, UserRole.CUSTOMS_AGENT]],
        [
          <AddCircleOutlineIcon />,
          t('New tour'),
          '/customs/tour/new',
          [UserRole.ADMIN, UserRole.CUSTOMS_AGENT, UserRole.WAREHOUSE_AGENT, UserRole.WAREHOUSE_SUPERVISOR],
        ],
        [<Settings />, t('Settings'), '/customs/settings', [UserRole.ADMIN, UserRole.CUSTOMS_AGENT]],
      ],
    ],
    null,
    [
      <HistoryIcon />,
      t('Activities'),
      '/activity',
      [UserRole.ADMIN, UserRole.CUSTOMS_AGENT, UserRole.CUSTOMER_CARE_AGENT],
    ],
    [<SaveAltIcon />, t('Documents'), '/jobs', [UserRole.ADMIN, UserRole.CUSTOMS_AGENT]],
    [<PeopleAltTwoToneIcon />, t('Users'), '/users', [UserRole.ADMIN, UserRole.WAREHOUSE_SUPERVISOR]],
    [<SettingsTwoToneIcon />, t('Return portal'), '/settings/returns', [UserRole.CUSTOMER]],
    [<SettingsTwoToneIcon />, t('Settings'), '/settings', []],
  ];

  if (!role) {
    return <LinearProgress />;
  }

  return (
    <>
      <List>
        {navigationEntries.map((entry, index) => {
          if (!entry) {
            return (
              <Divider
                key={index}
                sx={{ my: 1 }}
              />
            );
          }

          const [icon, label, path, userRoles, subEntries] = entry;

          if (userRoles.includes(role) || !userRoles.length) {
            return (
              <React.Fragment key={path}>
                <ListItemButton
                  component={Link}
                  selected={pathName.startsWith(path) && !subEntries?.length}
                  to={path}
                >
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText
                    primary={label}
                    inset={!icon}
                    sx={{ color: 'text.primary' }}
                  />
                </ListItemButton>
                {!!subEntries?.length && pathName.startsWith(path) && (
                  <List
                    component="div"
                    disablePadding
                  >
                    {subEntries.map(([icon, label, path, userRoles]) => {
                      if (userRoles.includes(role) || !userRoles.length) {
                        return (
                          <ListItemButton
                            key={`sub-${path}`}
                            component={Link}
                            selected={pathName.startsWith(path)}
                            to={path}
                            sx={{ pl: 4 }}
                          >
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText
                              primary={label}
                              inset={!icon}
                              sx={{ color: 'text.primary' }}
                            />
                          </ListItemButton>
                        );
                      }

                      return undefined;
                    })}
                  </List>
                )}
              </React.Fragment>
            );
          }

          return undefined;
        })}

        <ListItemButton
          component={Link}
          selected={'/logout' === pathName}
          to={`/logout`}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={'Log out'}
            sx={{ color: 'text.primary' }}
          />
        </ListItemButton>
      </List>
    </>
  );
};

export default Navigation;
