import { Button, Card, Input, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileSaver from 'file-saver';
import PrintIcon from '@mui/icons-material/Print';
import React, { FC, Fragment, useRef, useState } from 'react';

import * as OrderService from '../../../../../../../services/order-service/order.service';
import OrderDetailTable from './../../components/orderDetailTable';
import useStyles from './RegularOrderView.style';
import { LineItemDto } from '../../../../../../../services/line-item-service/lineItem.service';
import { getJobResultFileName, JobDto, jobService } from '../../../../../../../services/job-service/job.service';
import { ShipmentDto, ShipmentService } from '../../../../../../../services/shipment-service/shipment.service';
import { useNotifications } from '../../../../../../../hooks/useNotifications';
import { OrderType } from '../../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../../hooks/useAuthentication';
import { pdfFileDownload } from '../../../../../../../shared/helper/pdfFileDownload';
import { ProcessTypeService } from '../../../../../../../services/process-type-service/process-type.service';
import { t } from 'i18next';

const allItemsAssigned = (items: LineItemDto[], orderType: OrderType): boolean => {
  switch (orderType) {
    case OrderType.ORDER:
      return !(items.findIndex((item) => item.shipmentId === null) > -1);
    case OrderType.RETOURE:
      return !(items.findIndex((item) => item.returnShipmentId === null) > -1);
    default:
      return false;
  }
};

export interface RegularOrderProps {
  order: OrderService.OrderWithCustomerDto;
  shipments: ShipmentDto[];
  autofocus: boolean;
  orderTyp: OrderType;
  onLineItemAssign: (shipmentId: number, lineItemId: number) => Promise<void>;
  onLineItemAssignFinish: () => Promise<void>;
  reloadShipments: () => Promise<void>;
}

export const RegularOrderView: FC<RegularOrderProps> = (props) => {
  const notificationHandler = useNotifications();

  const classes = useStyles();
  const auth = useAuthentication();

  const [selectedShipmentId, setSelectedShipmentId] = useState<string>();
  const shipmentInputFieldRef = useRef<HTMLInputElement>(null);

  const processTypes = ProcessTypeService.useProcessTypes();
  const processType = processTypes.data.find((pt) => pt.processTypeId === props.order.process.processTypeId);

  const validInvoiceDestinationCountries = ['CH', 'GB', 'DE'];

  const handleLineItemAssign = async (shipmentId: number, lineItemId: number) => {
    await props.onLineItemAssign(shipmentId, lineItemId);
  };

  const handleLineItemAssignFinish = async () => {
    await props.onLineItemAssignFinish();
  };

  const handleCreateShipmentInvoice = async () => {
    const jobData = await ShipmentService.createCreateInvoiceJob(Number(selectedShipmentId));

    jobService.addJob(jobData.jobId.toString(), {
      onSuccess: async (jobId, jobObject: JobDto) => {
        const pdfBuffer = jobObject.returnvalue.file;
        const shipmentId = selectedShipmentId;
        if (pdfBuffer) {
          notificationHandler.addInfo(
            t(`The invoice for shipment #{{shipmentId}} has been successfully created and is being downloaded.`, {
              shipmentId,
            }),
          );
          pdfFileDownload(pdfBuffer, getJobResultFileName(jobObject));
        } else {
          const jobId = jobData.jobId;
          notificationHandler.addError(t(`Document from job {{jobId}} could not be downloaded`, { jobId }));
        }
      },
      onError: async (jobId, jobObject: JobDto) => {
        const shipmentId = selectedShipmentId;
        const error = jobObject.failedReason;
        notificationHandler.addError(
          t('Error when creating the invoice for shipment #{{shipmentId}}: {{error}}', { shipmentId, error }),
        );
      },
    });
  };

  const printReturnLabel = async () => {
    try {
      // Create an empty return shipment for the order and print the label
      const returnShipment = await ShipmentService.createReturnShipmentWithLabel(props.order.orderId);
      const filename = `return_label_${returnShipment.shipmentId}.pdf`;
      FileSaver.saveAs(`data:application/pdf;base64,${returnShipment.label}`, filename);
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  return (
    <Fragment>
      <Card className={classes.shippingCard}>
        <div className={classes.shippingHeader}>
          <div className={classes.shippingHeaderLeft}>
            <Typography
              variant="h5"
              component="h1"
            >
              {props.orderTyp === OrderType.ORDER ? t('Order') : t('Articles not returned')}
            </Typography>
          </div>
          <div className={classes.shippingHeaderRight}>
            {auth.isNotPicker() && props.orderTyp === OrderType.ORDER && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<PrintIcon />}
                onClick={() => {
                  printReturnLabel();
                }}
              >
                {t('Create new return label')}
              </Button>
            )}

            <div className="totalPriceContainer">
              <div className="totalPriceWrapper">
                <div
                  style={{
                    padding: '10px',
                    paddingLeft: '20px',
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    align="right"
                    variant="body1"
                    component="p"
                  >
                    {t('Total order price')}
                  </Typography>
                  <Typography
                    align="right"
                    variant="h6"
                    component="p"
                  >
                    {`${props.order.totalGross} ${props.order.currency}`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.shipments.some((shipment) => shipment.tourId) &&
          props.orderTyp === OrderType.ORDER &&
          processType &&
          validInvoiceDestinationCountries.includes(processType.destinationCountry) &&
          auth.isStaff() && (
            <div className={classes.shippingHeaderInvoices}>
              <Select
                variant="standard"
                className={classes.shippingHeaderInvoiceSelect}
                id="invoice-select"
                multiple={false}
                value={selectedShipmentId ?? ''}
                onChange={(event) => {
                  setSelectedShipmentId(event.target.value as string);
                }}
                input={
                  <Input
                    name="shipmentSelect"
                    ref={shipmentInputFieldRef}
                  />
                }
              >
                {props.shipments
                  .filter((shipment) => shipment.tourId)
                  .map((shipment, index) => (
                    <MenuItem
                      key={shipment.shipmentId}
                      value={shipment.shipmentId.toString()}
                    >
                      <ListItemText primary={shipment.externalShipmentId ?? `#${index + 1}`} />
                    </MenuItem>
                  ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateShipmentInvoice}
              >
                {t('Shipping invoice')}
              </Button>
            </div>
          )}

        <div>
          {!allItemsAssigned(props.order.lineItems, props.orderTyp) ? (
            <OrderDetailTable
              lineItems={props.order.lineItems}
              shipments={props.shipments}
              multiShipment={props.shipments.length > 0}
              onLineItemAssign={handleLineItemAssign}
              onLineItemAssignFinish={handleLineItemAssignFinish}
              orderTyp={props.orderTyp}
              reloadShipments={props.reloadShipments}
            />
          ) : props.orderTyp === OrderType.ORDER ? (
            <div className={classes.orderIsReadyWrapper}>
              <CheckCircleIcon
                style={{
                  marginLeft: '15px',
                  marginRight: '10px',
                }}
              />
              <Typography
                variant="h6"
                component="p"
              >
                {t('Each article was assigned to a package')}
              </Typography>
            </div>
          ) : (
            <div className={classes.orderIsReadyWrapper}>
              <Typography
                variant="h6"
                component="p"
              >
                {t('All articles in the order are in returns')}
              </Typography>
            </div>
          )}
        </div>
      </Card>
    </Fragment>
  );
};
