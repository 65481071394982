import { ConsignmentType } from '@exporto/types-customs';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  ConsignmentPartyDto,
  ConsignmentVehicleIdentificationDto,
  IAdditionalConsignmentData,
  CustomsService,
} from '../../services/customs-service/customs.service';
import { IPersonInCharge } from '../../services/customs-service/customs.types';
import { IsoCountryCode } from '../../shared/backend';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { useUserInfo } from '../../services/user-service/user.service';

const PERSON_IN_CHARGE = 'PERSON_IN_CHARGE';
const CONSIGNMENT_CARRIER = 'CONSIGNMENT_CARRIER';
const TRANSIT_CONSIGNOR = 'TRANSIT_CONSIGNOR';

export interface CreateConsignmentModalProps {
  open: boolean;
  tourIds: number[] | undefined;

  consignmentType: ConsignmentType | undefined;
  tourDispatchCountry: IsoCountryCode;

  onClose: () => void;
  createConsignments: (additionalConsignmentData: IAdditionalConsignmentData) => Promise<void>;
}

const CreateConsignmentModal: React.FC<CreateConsignmentModalProps> = ({
  open,
  tourIds,
  consignmentType,
  tourDispatchCountry,
  onClose,
  createConsignments,
}) => {
  const userInfo = useUserInfo();
  const notificationHandler = useNotifications();

  const personsInCharge = CustomsService.usePersonsInCharge();
  const vehicleIdentifications = CustomsService.useVehicleIdentifications();
  const carrierIdentifications = CustomsService.useConsignmentParties({ isCarrier: true });
  const transitConsignors = CustomsService.useConsignmentParties({ isTransitConsignor: true });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [personInCharge, setPersonInCharge] = useState<IPersonInCharge>();
  const [vehicleIdentification, setVehicleIdentification] = useState<ConsignmentVehicleIdentificationDto>();
  const [consignmentCarrier, setConsignmentCarrier] = useState<ConsignmentPartyDto>();
  const [consignmentTransitConsignor, setConsignmentTransitConsignor] = useState<ConsignmentPartyDto>();

  const areExportGbConsignments =
    consignmentType === ConsignmentType.EXPORT && tourDispatchCountry === IsoCountryCode.GB;

  const areExportDeConsignments =
    consignmentType === ConsignmentType.EXPORT && tourDispatchCountry === IsoCountryCode.DE;

  const showCarrier = areExportGbConsignments || areExportDeConsignments;
  const showTransitConsignor = consignmentType === ConsignmentType.TRANSIT_DEPARTURE;

  const resetModal = () => {
    setLoading(false);
    setSuccess(false);
  };

  const handleClose = () => {
    onClose();
    resetModal();
  };

  const personOfChargeByUser = personsInCharge.data.find(
    (person) => person.email.toLowerCase().trim() === userInfo.data?.email.toLowerCase().trim(),
  );

  useEffect(() => {
    setPersonInCharge(personOfChargeByUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personOfChargeByUser?.consignmentPersonInChargeId]);

  const handleDropdownValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;

    switch (target.name) {
      case PERSON_IN_CHARGE:
        const personInCharge = personsInCharge.data.find((personInCharge) => personInCharge.email === target.value);
        if (personInCharge) {
          setPersonInCharge(personInCharge);
        }
        break;
      case CONSIGNMENT_CARRIER:
        const consignmentCarrier = carrierIdentifications.data.find(
          (consignmentCarrier) => consignmentCarrier.consignmentPartyId === Number(target.value),
        );
        if (consignmentCarrier) {
          setConsignmentCarrier(consignmentCarrier);
        }
        break;
      case TRANSIT_CONSIGNOR:
        const consignmentTransitConsignor = transitConsignors.data.find(
          (consignmentTransitConsignor) => consignmentTransitConsignor.consignmentPartyId === Number(target.value),
        );
        if (consignmentTransitConsignor) {
          setConsignmentTransitConsignor(consignmentTransitConsignor);
        }
        break;
      default:
    }
  };

  const handleCreateConsignments = async () => {
    if (personInCharge && vehicleIdentification) {
      setLoading(true);

      try {
        await createConsignments({
          personInChargeId: personInCharge.consignmentPersonInChargeId,
          vehicleIdentificationId: vehicleIdentification.consignmentVehicleIdentificationId,
          carrierId: consignmentCarrier?.consignmentPartyId,
          transitConsignorId: consignmentTransitConsignor?.consignmentPartyId,
        });

        setSuccess(true);
        handleClose();
      } catch (error) {
        notificationHandler.addError(error);
        handleClose();
      }

      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>Consignment erstellen ({consignmentType})</DialogTitle>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!loading) {
            handleCreateConsignments();
          }
        }}
      >
        <DialogContent>
          <DialogContentText gutterBottom>
            Möchtest du ein Consignment für die folgenden Touren bei AEB erstellen?
          </DialogContentText>

          <DialogContentText>{tourIds?.join(', ')}</DialogContentText>

          <Stack
            direction="column"
            spacing={2}
            my={3}
          >
            <FormControl variant="outlined">
              <TextField
                select
                name={PERSON_IN_CHARGE}
                value={personInCharge?.email ?? ''}
                onChange={handleDropdownValueChange}
                disabled={personsInCharge.isLoading}
                label="Verantwortliche Person"
                required
              >
                {personsInCharge.data
                  .sort((a, b) => a.surname.localeCompare(b.surname))
                  .map((personInCharge) => (
                    <MenuItem
                      key={personInCharge.email}
                      value={personInCharge.email}
                    >{`${personInCharge.surname}, ${personInCharge.forename}`}</MenuItem>
                  ))}
              </TextField>
              {personsInCharge.isLoading && <LinearProgress />}
            </FormControl>

            <FormControl variant="outlined">
              <SelectWithFilter
                values={vehicleIdentifications.data
                  .sort((a, b) => a.identification.localeCompare(b.identification))
                  .map((vehicleIdentification) => ({
                    id: vehicleIdentification.identification,
                    label: vehicleIdentification.identification,
                  }))}
                selectedValueIds={[vehicleIdentification?.identification ?? '']}
                onChange={([identification]) => {
                  if (!identification) {
                    // selection was cleared
                    setVehicleIdentification(undefined);
                    return;
                  }

                  const vehicleIdentification = vehicleIdentifications.data.find(
                    (vehicleIdentification) => vehicleIdentification.identification === identification,
                  );

                  if (vehicleIdentification) {
                    setVehicleIdentification(vehicleIdentification);
                  }
                }}
                label="Fahrzeugkennzeichen"
                required
              />
              {vehicleIdentifications.isLoading && <LinearProgress />}
            </FormControl>

            {showCarrier && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={CONSIGNMENT_CARRIER}
                  value={consignmentCarrier?.consignmentPartyId ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={carrierIdentifications.isLoading}
                  label="Carrier"
                  required
                >
                  {carrierIdentifications.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((consignmentCarrier) => (
                      <MenuItem
                        key={consignmentCarrier.name}
                        value={consignmentCarrier.consignmentPartyId}
                      >
                        {consignmentCarrier.name}
                      </MenuItem>
                    ))}
                </TextField>
                {carrierIdentifications.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {showTransitConsignor && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={TRANSIT_CONSIGNOR}
                  value={consignmentTransitConsignor?.consignmentPartyId ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={transitConsignors.isLoading}
                  label="Transit-Anmelder"
                  required
                >
                  {transitConsignors.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((consignmentTransitConsignor) => (
                      <MenuItem
                        key={consignmentTransitConsignor.name}
                        value={consignmentTransitConsignor.consignmentPartyId}
                      >
                        {consignmentTransitConsignor.name}
                      </MenuItem>
                    ))}
                </TextField>
                {transitConsignors.isLoading && <LinearProgress />}
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={success || loading || !personInCharge || !vehicleIdentification}
            startIcon={
              loading ? (
                <CircularProgress
                  color="primary"
                  size="1em"
                />
              ) : undefined
            }
          >
            Consignment erstellen
          </Button>
          <Button
            onClick={() => handleClose()}
            variant="contained"
            color="primary"
          >
            {success ? 'Schließen' : 'Abbrechen'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateConsignmentModal;
