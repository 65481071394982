import { TableRow, TableCell, Typography, Button, Link } from '@mui/material';
import React, { Fragment } from 'react';
import { LineItemDto } from '../../../../../../../../services/line-item-service/lineItem.service';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import BlockIcon from '@mui/icons-material/Block';
import { SplitLineItem } from './SplitLineItem';
import { Stack } from '@mui/system';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { ArticleService } from '../../../../../../../../services/article-service/article.service';
import { useAuthentication } from '../../../../../../../../hooks/useAuthentication';

type Props = {
  item: LineItemDto;
  disableShipmentEditing: boolean;
  onLineItemDelete: (lineItemId: number) => void;
  reloadShipments: () => Promise<void>;
  showArticleWeights?: boolean;
};

const ShipmentLineItemViewRow: React.FC<Props> = ({
  item,
  disableShipmentEditing,
  onLineItemDelete,
  reloadShipments,
  showArticleWeights,
}: Props) => {
  const auth = useAuthentication();

  const article = ArticleService.useArticleByExternalArticleId(item.processId, item.externalArticleId, auth.isStaff());

  const handleLineItemDelete = (event: React.MouseEvent<HTMLButtonElement>, lineItemId: number) => {
    event.stopPropagation();
    onLineItemDelete(lineItemId);
  };

  const articleHasNoWeights = !article.data?.weight && !article.data?.defaultWeight;

  return (
    <TableRow
      hover
      key={item.lineItemId}
    >
      <TableCell
        component="th"
        scope="row"
      >
        <Typography
          variant="h6"
          component="p"
        >
          {!!article.data?.warning && !article.data?.isBlocked && auth.isPermanentEmployee() && (
            <ArticleIcon
              color="warning"
              sx={{ mr: '0.5em', verticalAlign: 'bottom' }}
            />
          )}
          {article.data?.isBlocked && auth.isStaff() && (
            <BlockIcon
              color="error"
              sx={{ mr: '0.5em', verticalAlign: 'bottom' }}
            />
          )}
          {articleHasNoWeights && (
            <FitnessCenterIcon
              color="error"
              sx={{ mr: '0.5em', verticalAlign: 'bottom' }}
            />
          )}
          <Link
            href={
              article.data?.articleId
                ? `/articles?articleId=${article.data.articleId}`
                : `/articles?q=${item.externalArticleId}`
            }
            underline="hover"
            color="inherit"
            target="_blank"
          >
            {item.name || article.data?.name}
          </Link>
        </Typography>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
      >
        <Typography
          variant="h6"
          component="p"
        >
          {item.priceGross}
        </Typography>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ width: 1 / 5 }}
      >
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Typography
            variant="h6"
            component="p"
            display="inline"
          >
            {item.quantity}
          </Typography>
          <SplitLineItem
            item={item}
            reloadShipments={reloadShipments}
          />
        </Stack>
      </TableCell>
      {showArticleWeights && (
        <Fragment>
          <TableCell
            component="th"
            scope="row"
          >
            <Typography
              variant="h6"
              component="p"
              color={articleHasNoWeights ? 'error' : undefined}
            >
              {article.data?.weight ?? '-'} g
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            <Typography
              variant="h6"
              component="p"
              color={articleHasNoWeights ? 'error' : undefined}
            >
              {article.data?.defaultWeight ?? 0} g
            </Typography>
          </TableCell>
        </Fragment>
      )}
      {auth.isStaff() && (
        <TableCell
          component="th"
          scope="row"
          align="right"
        >
          <Typography
            variant="h6"
            component="p"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleLineItemDelete(event, item.lineItemId)}
              disabled={disableShipmentEditing}
            >
              <DeleteIcon />
            </Button>
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ShipmentLineItemViewRow;
