import React, { useState } from 'react';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { Stack, Typography, CircularProgress, Button, Box, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import { ExportoBarcode } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/ExportoBarcode';
import { ReturnPackageIdentifier } from './ReturnPackageIdentifier';
import { ShippingNumber } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/ShippingNumber';
import ShipmentStateCircle from './ShipmentStateCircle';
import { useAuthentication } from '../../hooks/useAuthentication';
import ShipmentWeight from './ShipmentWeight';
import { OrderService, OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import { useNotifications } from '../../hooks/useNotifications';
import { Warning } from '@mui/icons-material';
import { getConsignedInboundLineItemIds, getTotalOrderWeight, isAssignAllLineItems } from './shipment.helper';
import { OrderType, ShipmentState, ShipmentType } from '../../shared/backend';
import { useDebugView } from '../../shared/hooks/dev-mode';
import { useNavigate } from 'react-router-dom';
import { confirmWeightWarningDialog } from '../orders/shared/components/order-detail-modal/components/shipment-view/WeightWarningDialog';
import { useHistoryRef } from '../../hooks/useHistoryNavigation';
import { useScale } from '../../contexts/ScaleContext';
import { t } from 'i18next';
import HistoryIconButton from '../../shared/components/HistoryIconButton';

type Props = {
  shipment: ShipmentDto;
  order: OrderWithShipmentsAndToursDto;
  heading: string;
  editMode: boolean;
  readyForNext: () => void;
};

const ShipmentHeader: React.FC<Props> = ({ shipment, order, heading, editMode, readyForNext }) => {
  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);
  const outgoingShipments = ShipmentService.useShipmentsByOrder(shipment.orderId, OrderType.ORDER);
  const [isDebugView] = useDebugView();
  const notifications = useNotifications();
  const navigateTo = useNavigate();
  const auth = useAuthentication();
  const historyRef = useHistoryRef();
  const scale = useScale();

  const [isWorking, setWorking] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const shipmentState = shipment.state;

  const done = () => (!editMode ? readyForNext() : undefined);
  const isEmpty = shipment.lineItems.length === 0;
  const isInvalidShipment = !shipment.shippingWeight || isEmpty;
  const isAllowedToDispose = auth.isPermanentEmployee() && shipment.state === ShipmentState.TO_BE_CLARIFIED;
  const isReadyToProcess =
    (shipment.state === ShipmentState.RETOURE_ANNOUNCED || isAllowedToDispose) && !isInvalidShipment;

  const totalOrderWeight = getTotalOrderWeight(outgoingShipments.data);

  const updateShipmentState = async (state: ShipmentState) => {
    try {
      await ShipmentService.updateShipment(
        shipment.shipmentId,
        {
          state,
        },
        { referrer: historyRef ?? undefined },
      );

      done();

      await mutate();
    } catch (err) {
      notifications.addError(err);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <ShipmentStateCircle shipmentState={shipmentState} />

      <Tooltip title={heading}>
        <Typography
          variant="h5"
          component="h1"
          sx={{
            maxWidth: '10em',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {heading}
        </Typography>
      </Tooltip>

      <HistoryIconButton
        entityName="shipment"
        entityId={shipment.shipmentId}
      />

      <ShipmentWeight
        shipment={shipment}
        disabled={(shipmentState !== ShipmentState.RETOURE_ANNOUNCED || shipment.isConsigned) && !editMode}
        totalOrderWeight={totalOrderWeight}
        onSubmit={async (weight) => {
          setWorking(true);

          let allLineItemsAssigned = false;

          const shouldAssignAllLineItems = isAssignAllLineItems(shipment, totalOrderWeight, weight);

          if (shouldAssignAllLineItems) {
            try {
              const consignedLineItemIds = getConsignedInboundLineItemIds(outgoingShipments.data);
              const unassignedLineItems = order.lineItems.filter(
                (lineItem) => !lineItem.returnShipmentId && consignedLineItemIds.includes(lineItem.lineItemId),
              );

              for (const { lineItemId } of unassignedLineItems) {
                await ShipmentService.addLineItemToShipment(shipment.shipmentId, lineItemId);
              }

              if (unassignedLineItems.length) {
                allLineItemsAssigned = true;
                notifications.addSuccess(t('All articles have been added'));
              }
            } catch (err) {
              notifications.addError(err);

              return;
            }
          }

          const isNowReadyToProcess = shipment.state === ShipmentState.RETOURE_ANNOUNCED && !isEmpty && !!weight;

          if (
            shipment.type === ShipmentType.RETOUR &&
            (allLineItemsAssigned || isReadyToProcess || isNowReadyToProcess)
          ) {
            try {
              await ShipmentService.acceptReturnShipment(shipment.shipmentId, {
                referrer: historyRef ?? undefined,
                scaleWeight: scale.weight,
              });

              done();
            } catch (err) {
              notifications.addError(err);
            }
          }

          await mutate();

          setWorking(false);
        }}
      />

      {isDebugView && (
        <Box pl={2}>
          <Typography
            variant="body1"
            component="p"
          >
            Bestellgewicht
          </Typography>
          <Typography
            variant="h6"
            component="p"
          >
            {totalOrderWeight}g
          </Typography>
        </Box>
      )}

      <ExportoBarcode shipment={shipment} />

      <ShippingNumber shipment={shipment} />

      {auth.isStaff() && shipment.type === ShipmentType.SHIPMENT && <ReturnPackageIdentifier shipment={shipment} />}

      <Box flexGrow={1}></Box>

      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
      >
        {isWorking && <CircularProgress color="secondary" />}

        {shipmentState === ShipmentState.RETOURE_ANNOUNCED && !!shipment.returnPackageIdentifier && (
          <Tooltip title={t('The shipment has already been processed')}>
            <Warning color="warning" />
          </Tooltip>
        )}

        {(shipmentState === ShipmentState.RETOURE_ANNOUNCED ||
          shipmentState === ShipmentState.RETOURE_REJECTED ||
          (shipmentState === ShipmentState.TO_BE_CLARIFIED && auth.isPermanentEmployee()) ||
          editMode) && (
          <>
            <Button
              variant="contained"
              color="printed"
              onClick={async () => {
                try {
                  if (shipment.shippingWeight !== null && shipment.shippingWeight / totalOrderWeight > 1.05) {
                    if (!(await confirmWeightWarningDialog({ weight: shipment.shippingWeight }))) {
                      return;
                    }
                  }

                  await ShipmentService.acceptReturnShipment(shipment.shipmentId, {
                    referrer: historyRef ?? undefined,
                    scaleWeight: scale.weight,
                  });

                  done();

                  await mutate();
                } catch (err) {
                  notifications.addError(err);
                }
              }}
              disabled={!isReadyToProcess || shipmentState === ShipmentState.RETOURE_TOUR_READY}
              startIcon={<CheckIcon />}
              tabIndex={3}
            >
              Bestätigen
            </Button>

            {shipmentState !== ShipmentState.TO_BE_CLARIFIED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.TO_BE_CLARIFIED);
                }}
                disabled={!isReadyToProcess && !editMode}
                startIcon={<ContactSupportIcon />}
              >
                {t('Need for clarification')}
              </Button>
            )}

            {shipmentState === ShipmentState.TO_BE_CLARIFIED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.DISPOSED);
                }}
                disabled={!isReadyToProcess}
                startIcon={<DeleteForeverIcon />}
              >
                Entsorgen
              </Button>
            )}

            {shipment.state !== ShipmentState.RETOURE_ANNOUNCED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.RETOURE_ANNOUNCED);
                }}
                startIcon={<FiberNewIcon />}
              >
                {t('Announced')}
              </Button>
            )}
          </>
        )}

        {auth.isPermanentEmployee() && (
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              const nextShipment = order.shipments.filter(({ shipmentId }) => shipmentId !== shipment.shipmentId)[0];
              const shipmentId = shipment.shipmentId;

              if (nextShipment) {
                setDeleting(true);

                try {
                  await ShipmentService.deleteShipment(shipmentId);

                  navigateTo(`/shipment/${nextShipment.shipmentId}`);

                  notifications.addSuccess(t(`Shipment {{shipmentId}} has been deleted`));

                  mutate();
                } catch (error) {
                  notifications.addError(error);
                } finally {
                  setDeleting(false);
                }
              }
            }}
            disabled={
              shipment.isConsigned ||
              (shipment.state !== ShipmentState.RETOURE_ANNOUNCED && !editMode) ||
              order.shipments.length <= 1 ||
              isDeleting
            }
          >
            {isDeleting ? <CircularProgress size="1.6em" /> : <DeleteIcon />}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ShipmentHeader;
