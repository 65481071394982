import React from 'react';
import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ProcessDto } from '../../../../../../../../services/process-service/process.service';
import { ShipmentDto } from '../../../../../../../../services/shipment-service/shipment.service';
import { NotificationState, ShipmentCarrierState, ShipmentType } from '../../../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../../../hooks/useAuthentication';
import { ImporterService } from '../../../../../../../../services/importer-service/importer.service';
import { useNotifications } from '../../../../../../../../hooks/useNotifications';
import PendingIcon from '@mui/icons-material/Pending';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import DeliveredIcon from '../../../../../../../../shared/DeliveredIcon';
import { t } from 'i18next';

export const carrierStateIcons = {
  [ShipmentCarrierState.NOT_AVAILABLE]: <LocationOffIcon color="disabled" />,
  [ShipmentCarrierState.PENDING]: <PendingIcon color="disabled" />,
  [ShipmentCarrierState.IN_DELIVERY]: <LocalShippingIcon />,
  [ShipmentCarrierState.DELIVERED]: <DeliveredIcon color="success" />,
  [ShipmentCarrierState.DELIVERY_FAILED]: <FmdBadIcon color="error" />,
};

export const notificationIcons = {
  [NotificationState.DELIVERED]: <NotificationsIcon color="success" />,
  [NotificationState.PENDING]: <NotificationsPausedIcon />,
  [NotificationState.FAILED]: <NotificationImportantIcon color="error" />,
};

interface IShippingNumberProps {
  shipment: ShipmentDto;
  process?: ProcessDto | null;
}

const getTrackingIdHref = (trackingNumber: string) => {
  const trackingNumberLength = trackingNumber.trim().length;
  let returnLink = '#';

  if (trackingNumberLength === 10 || trackingNumberLength === 18 || trackingNumberLength === 21) {
    returnLink = `https://www.post.ch/swisspost-tracking?formattedParcelCodes=${trackingNumber}`;
  } else if (trackingNumberLength === 14) {
    returnLink = `https://tracking.dpd.de/parcelstatus?query=${trackingNumber}`;
  } else if (trackingNumberLength === 16) {
    const trackingNumberForUrl = trackingNumber.replaceAll('-', '');
    returnLink = `https://www.evri.com/track/#/parcel/${trackingNumberForUrl}/details`;
  } else if (trackingNumberLength === 20) {
    const trackingNumberForUrl = trackingNumber.replaceAll('-', '');
    returnLink = `https://www.evri.com/track/#/parcel/${trackingNumberForUrl}/details`;
  } else if (/^[A-Z]{2}\d{9}GB$/.test(trackingNumber)) {
    // https://www.royalmail.com/royal-mail-you/intellectual-property-rights/linking-our-website
    returnLink = `http://www.royalmail.com/portal/rm/track?trackNumber=${trackingNumber}`;
  } else if (/^[CER][A-Z]\d{9}FR$/.test(trackingNumber) || /^[6-9][A-Z]\d{11}$/.test(trackingNumber)) {
    // https://www.colissimo.entreprise.laposte.fr/sites/default/files/2021-08/CDC_WebServiceTrackingTL-v2.1_EN.pdf
    returnLink = `https://www.laposte.fr/outils/suivre-vos-envois?code=${trackingNumber}`;
  }

  return returnLink;
};

export const ShippingNumber: React.FC<IShippingNumberProps> = ({ shipment, process }) => {
  const auth = useAuthentication();
  const notifications = useNotifications();

  const trackingId =
    shipment.type === ShipmentType.SHIPMENT ? shipment.foreignOutboundTrackingId : shipment.foreignInboundTrackingId;
  const trackingIdHref = getTrackingIdHref(trackingId ?? '');

  const carrierWeight = shipment.carrierWeight ?? '?';
  const receivedDate = shipment.carrierReceivedDate ? new Date(shipment.carrierReceivedDate).toLocaleString() : '?';
  const deliveryDate = shipment.deliveryDate ? new Date(shipment.deliveryDate).toLocaleString() : '?';
  const carrierState = shipment.carrierState;

  return (
    <Box sx={{ padding: '10px', flexGrow: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >
        {shipment.type === ShipmentType.SHIPMENT && process && (
          <>
            {!process.notificationsEnabled && (
              <Tooltip title="Notifications disabled">
                <NotificationsOffIcon sx={(theme) => ({ opacity: theme.palette.action.disabledOpacity })} />
              </Tooltip>
            )}
            {process.notificationsEnabled && !shipment.notification && (
              <Tooltip title="Notifications enabled">
                <NotificationsNoneIcon sx={(theme) => ({ opacity: theme.palette.action.disabledOpacity })} />
              </Tooltip>
            )}

            {shipment.notification === NotificationState.PENDING && (
              <Tooltip title="Notification is currently being delivered">
                {notificationIcons[shipment.notification]}
              </Tooltip>
            )}
            {shipment.notification === NotificationState.DELIVERED && (
              <Tooltip title="Notification delivered">{notificationIcons[shipment.notification]}</Tooltip>
            )}
            {shipment.notification === NotificationState.FAILED && (
              <Tooltip title="Notification failed">
                {auth.isOffice() ? (
                  <IconButton
                    color="error"
                    onClick={async () => {
                      await ImporterService.retryShipmentNotification(shipment.shipmentId);

                      notifications.addSuccess('Notification queued');
                    }}
                  >
                    {notificationIcons[shipment.notification]}
                  </IconButton>
                ) : (
                  notificationIcons[shipment.notification]
                )}
              </Tooltip>
            )}
          </>
        )}

        {auth.isNotPicker() &&
          shipment.carrierState &&
          shipment.carrierState !== ShipmentCarrierState.NOT_AVAILABLE && (
            <Tooltip
              title={
                <Box
                  component="ul"
                  sx={{ m: 0, p: 0, listStyleType: 'none' }}
                >
                  {[
                    t('Carrier state: {{carrierState}}', { carrierState }),
                    auth.isStaff() ? t('Carrier weight: {{carrierWeight}}', { carrierWeight }) : null,
                    t('Carrier received: {{receivedDate}}', { receivedDate }),
                    t('Delivered: {{deliveryDate}}', { deliveryDate }),
                  ]
                    .filter((text) => !!text)
                    .map((text) => (
                      <li key={text}>{text}</li>
                    ))}
                </Box>
              }
            >
              {carrierStateIcons[shipment.carrierState]}
            </Tooltip>
          )}

        <Box>
          <Typography
            variant="body1"
            component="p"
          >
            Sendungsnummer
          </Typography>
          <Typography
            variant="h6"
            component="p"
          >
            {trackingId ? (
              trackingIdHref !== '#' ? (
                <Link
                  sx={{ color: 'inherit' }}
                  href={trackingIdHref}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {trackingId}
                </Link>
              ) : (
                trackingId
              )
            ) : (
              <em>{t('No label printed')}</em>
            )}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
