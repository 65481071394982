import { useSnackbar, VariantType } from 'notistack';
import { useCallback, useMemo } from 'react';

import axios from 'axios';

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addError = useCallback(
    (error: unknown, duration: number | null = 5000, key?: string) => {
      let message: string = '';
      let variant: VariantType = 'error';

      if (axios.isAxiosError(error) && error.code === 'ERR_NETWORK') {
        message = `Netzwerkfehler: Server nicht erreichbar. (${error.message})`;
      } else if (axios.isAxiosError(error) && error.response) {
        console.warn('Axios error response: ', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        });

        if (error.response.status === 404) {
          variant = 'warning';
        }

        // ArrayBuffer handling
        if (error.response.data instanceof ArrayBuffer) {
          const decoder = new TextDecoder('utf-8');
          try {
            error.response.data = JSON.parse(decoder.decode(error.response.data));
          } catch (e) {
            console.log('Could not convert ArrayBuffer to error object', error);
          }
        }

        const responseData = error.response?.data as Record<string, unknown>;

        if (Array.isArray(responseData?.message)) {
          const errorArray = responseData.message;
          message = errorArray[0];
        } else if (responseData?.message) {
          message = responseData.message.toString();

          if (responseData.context) {
            message += ` | ${JSON.stringify(responseData.context)}`;
          }
        } else {
          const url = error.response?.config?.url || '<unknown url>';
          const status = error.response.status;

          message = `Der Server antwortet mit einem Fehlercode ${status} für ${url}`;
        }
      } else if (axios.isAxiosError(error) && error.request) {
        console.log(error.request);
        message = 'Der Server antwortet nicht';
      } else {
        console.log(error);
        message = `Ein Fehler ist aufgetreten: ${error}`;
      }

      enqueueSnackbar(message || 'Ein Fehler ohne Beschreibung ist aufgetreten', {
        variant,
        autoHideDuration: duration,
        key,
      });
    },
    [enqueueSnackbar],
  );

  const addWarning = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      enqueueSnackbar(message, { variant: 'warning', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const addInfo = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      enqueueSnackbar(message, { variant: 'info', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const addSuccess = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const close = useCallback(
    (key: string) => {
      closeSnackbar(key);
    },
    [closeSnackbar],
  );

  const value = useMemo(
    () => ({ addError, addWarning, addInfo, addSuccess, close }),
    [addError, addWarning, addInfo, addSuccess, close],
  );

  return value;
};
