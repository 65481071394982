import { Stack, Typography } from '@mui/material';
import React from 'react';
import UserLabel from '../activity/components/UserLabel';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

type Props = {
  shipment: ShipmentDto;
};

const ShipmentFooter: React.FC<Props> = ({ shipment }) => {
  const auth = useAuthentication();

  const { shipmentId, externalShipmentId, processedAt, returnPackageIdentifier } = shipment;
  const processedByUserId = auth.isStaff() ? shipment.processedByUserId : null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      marginTop={1}
    >
      <Typography
        variant="body2"
        color="text.disabled"
      >
        {t('Shipment ID: {{shipmentId}}', { shipmentId })}
        {shipment.externalShipmentId
          ? `, ${t('External ID: {{externalShipmentId}}', { externalShipmentId })}`
          : undefined}
        {shipment.returnPackageIdentifier
          ? `, ${t('Return package no.: {{returnPackageIdentifier}}', { returnPackageIdentifier })}`
          : undefined}
      </Typography>

      {(processedAt || processedByUserId) && (
        <Typography
          variant="body2"
          color="text.disabled"
        >
          {processedAt && processedByUserId ? (
            <Trans shouldUnescape={true}>
              Processed by <UserLabel userId={processedByUserId} /> at {{ processedAt }}
            </Trans>
          ) : processedByUserId ? (
            <Trans>
              Processed by <UserLabel userId={processedByUserId} />
            </Trans>
          ) : processedAt ? (
            <>{t('Processed at {{processedAt}}', { processedAt })}</>
          ) : (
            ''
          )}
        </Typography>
      )}
    </Stack>
  );
};

export default ShipmentFooter;
